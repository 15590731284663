/**
 * Customer Dashboard Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  executePaymentFailure,
  executePaymentSuccess,
  getChannelCountReportFailure,
  getChannelCountReportSuccess,
  getConsentCheckCountSuccess,
  getCostPerActivitySuccess,
  getCostPerContactSuccess,
  getCustomerEventReportFailure,
  getCustomerEventReportSuccess,
  getCustomerLedgerReportFailure,
  getCustomerLedgerReportSuccess,
  getCustomerListFailure,
  getCustomerListSuccess,
  getDomainCampaignReportFailure,
  getDomainCampaignReportSuccess,
  getDomainEventReportFailure,
  getDomainEventReportSuccess,
  getDomainLedgerReportFailure,
  getDomainLedgerReportSuccess,
  refundPaymentFailure,
  refundPaymentSuccess,
  getCustomerConsentReportSuccess,
  getCustomerConsentReportFailure,
  getCustomerConsumerReportSuccess,
  getCustomerConsumerReportFailure,
} from "../actions";
// app config
import { getAxiosRequest, getContextObject } from "../helpers/helpers";

export const reports = (state) => state.reportReducer;
export const authUser = (state) => state.authUser;

const getCustomerEventReportFromServer = async (email) => {
  if (email === null) {
    return await getAxiosRequest().get('/customer/eventreport?email=""');
  } else {
    return await getAxiosRequest().get("/customer/eventreport?email=" + email);
  }
};

const getChannelCountFromServer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain },
  };

  return await getAxiosRequest().get("/manage/domain/channelcount", config);
};

const getCostPerActivity = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain },
  };

  return await getAxiosRequest().get(
    "/manage/customer/costperactivity",
    config
  );
};

const getCostPerContact = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.currentDomain },
  };

  return await getAxiosRequest().get(
    "/manage/customer/costpercontact?count=" + model.count,
    config
  );
};

const getConsentChecks = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain },
  };

  return await getAxiosRequest().get("/audit/domain/consents", config);
};

const getCustomerListFromServer = async () => {
  return await getAxiosRequest().get("/customer");
};

const getCustomerLedgerReportFromServer = async () => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;

  // if (email === null) {
  //   return await getAxiosRequest().get('/customer/ledgerreport?email=""');
  // } else {
  //   return await getAxiosRequest().get("/customer/ledgerreport?email=" + email);
  // }

  return await getAxiosRequest().get(
    `/customer/ledgerreport?customerId=${currentCustomer}`
  );
};

const getConsumerDataFromServer = async (model) => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;

  var payload = {
    noOfDays: 30,
    domainList: [],
  };

  return await getAxiosRequest().post(
    `/customer/${currentCustomer}/domaincounts`,
    payload
  );
};

const getConsentDataFromServer = async (model) => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;

  var payload = {
    noOfDays: model,
    domainList: [],
  };

  return await getAxiosRequest().post(
    `/customer/${currentCustomer}/domaineventcounts`,
    payload
  );
};

const excutePaymentWithPaypal = async (model) => {
  return await getAxiosRequest().post("/payment", model);
};

const refundPaymentWithPaypal = async (model) => {
  return await getAxiosRequest().put("/payment", model);
};
const getDomainEventReportCall = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domainId },
  };
  return await getAxiosRequest().get(
    `/manage/customer/domainevents?filterType=${model.filterType}&filter=${model.filter}`,
    config
  );
};
const getDomainCampaignReportCall = async (domainId) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: domainId },
  };
  return await getAxiosRequest().get(
    `/manage/customer/domaineventreport`,
    config
  );
};
const getDomainLedgerReportCall = async (domainId) => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  var config = {};
  config = {
    headers: { DOMAIN_KEY: domainId },
  };
  return await getAxiosRequest().get(
    `/customer/${currentCustomer}/ledgerreport`,
    config
  );
};

/**
 * Get Event Report
 */
function* getEventReport() {
  let customer = yield select(reports);
  if (customer.selectedCustomer !== null) {
    try {
      const response = yield call(
        getCustomerEventReportFromServer,
        customer.selectedCustomer.email
      );
      yield put(getCustomerEventReportSuccess(response));
    } catch (error) {
      yield put(getCustomerEventReportFailure(error.response.data.error));
    }
  } else {
    try {
      const response = yield call(getCustomerEventReportFromServer, null);
      yield put(getCustomerEventReportSuccess(response));
    } catch (error) {
      yield put(getCustomerEventReportFailure(error.response.data.error));
    }
  }
}

/**
 * Get Customer List
 */
function* getCustomers() {
  try {
    const response = yield call(getCustomerListFromServer);
    yield put(getCustomerListSuccess(response));
  } catch (error) {
    yield put(getCustomerListFailure(error.response.data.error));
  }
}

/**
 * Get Ledger Report
 */
function* getLedgerReport() {
  let customer = yield select(reports);
  if (customer.selectedCustomer) {
    try {
      const response = yield call(
        getCustomerLedgerReportFromServer,
        customer.selectedCustomer.email
      );
      yield put(getCustomerLedgerReportSuccess(response));
    } catch (error) {
      yield put(getCustomerLedgerReportFailure(error.response.data.error));
    }
  } else {
    try {
      const response = yield call(getCustomerLedgerReportFromServer, null);
      yield put(getCustomerLedgerReportSuccess(response));
    } catch (error) {
      yield put(getCustomerLedgerReportFailure(error.response.data.error));
    }
  }
}

function* executePayment() {
  try {
    try {
      let customer = yield select(reports);

      const paymentResponse = yield call(
        excutePaymentWithPaypal,
        customer.payment
      );
      yield put(executePaymentSuccess(paymentResponse));
      const ledgerReport = yield call(getCustomerLedgerReportFromServer, null);
      yield put(getCustomerLedgerReportSuccess(ledgerReport));
    } catch (error) {
      yield put(executePaymentFailure(error.response.data.error));
    }
  } catch (error) {
    yield put(executePaymentFailure(error.response.data.error));
  }
}

function* refundPayment() {
  try {
    try {
      let customer = yield select(reports);
      var model = {
        transactionId: customer.refundTransaction,
      };
      const paymentResponse = yield call(refundPaymentWithPaypal, model);
      yield put(refundPaymentSuccess(paymentResponse));
      const ledgerReport = yield call(getCustomerLedgerReportFromServer, null);
      yield put(getCustomerLedgerReportSuccess(ledgerReport));
    } catch (error) {
      yield put(refundPaymentFailure(error.response.data.error));
    }
  } catch (error) {
    yield put(refundPaymentFailure(error.response.data.error));
  }
}

/**
 * Get Channel Count
 */
function* getChannelCount() {
  let customer = yield select(reports);
  if (customer.selectedCustomer !== null) {
    try {
      const response = yield call(
        getChannelCountFromServer,
        customer.selectedCustomer.domains[0].domainId
      );
      if (response.data.result.userCount > 0) {
        if (response.data.result.userCount > 0) {
          const activity = yield call(
            getCostPerActivity,
            customer.selectedCustomer.domains[0].domainId
          );
          yield put(getCostPerActivitySuccess(activity));
          var model = {
            currentDomain: customer.selectedCustomer.domains[0].domainId,
            count: response.data.result.userCount,
          };

          const contact = yield call(getCostPerContact, model);
          yield put(getCostPerContactSuccess(contact));

          const consentChecks = yield call(
            getConsentChecks,
            customer.selectedCustomer.domains[0].name
          );
          yield put(getConsentCheckCountSuccess(consentChecks));
        }
      }
      yield put(getChannelCountReportSuccess(response));
    } catch (error) {
      yield put(getChannelCountReportFailure(error.response.data.error));
    }
  } else {
    try {
      let user = yield select(authUser);
      const response = yield call(
        getChannelCountFromServer,
        user.currentDomain.id
      );

      yield put(getChannelCountReportSuccess(response));

      if (response.data.result.userCount > 0) {
        const activity = yield call(getCostPerActivity, user.currentDomain.id);
        yield put(getCostPerActivitySuccess(activity));
        // eslint-disable-next-line no-redeclare
        var model = {
          currentDomain: user.currentDomain.id,
          count: response.data.result.userCount,
        };

        const contact = yield call(getCostPerContact, model);
        yield put(getCostPerContactSuccess(contact));

        const consentChecks = yield call(
          getConsentChecks,
          user.currentDomain.name
        );
        yield put(getConsentCheckCountSuccess(consentChecks));
      }
    } catch (error) {
      yield put(getChannelCountReportFailure(error.response.data.error));
    }
  }
}

/**
 * Get Channel Count for a domain
 */
function* getChannelCountForDomain() {
  let customer = yield select(reports);

  if (customer.selectedCustomer !== null) {
    try {
      const response = yield call(
        getChannelCountFromServer,
        customer.selectedCustomerDomain.domainId
      );
      yield put(getChannelCountReportSuccess(response));
      if (response.data.result.userCount > 0) {
        const activity = yield call(
          getCostPerActivity,
          customer.selectedCustomerDomain.domainId
        );
        yield put(getCostPerActivitySuccess(activity));
        var model = {
          currentDomain: customer.selectedCustomerDomain.domainId,
          count: response.data.result.userCount,
        };

        const contact = yield call(getCostPerContact, model);
        yield put(getCostPerContactSuccess(contact));

        const consentChecks = yield call(
          getConsentChecks,
          customer.selectedCustomerDomain.name
        );
        yield put(getConsentCheckCountSuccess(consentChecks));
      }
    } catch (error) {
      yield put(getChannelCountReportFailure(error.response.data.error));
    }
  } else {
    try {
      let user = yield select(authUser);
      const response = yield call(
        getChannelCountFromServer,
        user.currentDomain.id
      );
      yield put(getChannelCountReportSuccess(response));
    } catch (error) {
      yield put(getChannelCountReportFailure(error.response.data.error));
    }
  }
}
function* getDomainEventsReport() {
  let customer = yield select(reports);

  if (customer.selectedCustomerDomainForEvent != null) {
    let model = {
      domainId: customer.selectedCustomerDomainForEvent.domainId,
      filterType: customer.filterModel.type,
      filter: customer.filterModel.value,
    };
    try {
      const response = yield call(getDomainEventReportCall, model);
      if (response) {
        yield put(getDomainEventReportSuccess(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      let user = yield select(authUser);
      let customer = yield select(reports);
      let model = {
        domainId: user.currentDomain.id,
        filterType: customer.filterModel.type,
        filter: customer.filterModel.value,
      };
      const response = yield call(getDomainEventReportCall, model);
      yield put(getDomainEventReportSuccess(response.data));
    } catch (error) {
      yield put(getDomainEventReportFailure(error.response.data.error));
    }
  }
}
// function* changeDomain(){
//     let customer = yield select(reports);
//     if(customer.reportType==null){
//         yield put()
//     }
// }
function* getDomainCampainReport() {
  let customer = yield select(reports);
  let domainId = customer.selectedCustomerDomainForEvent
    ? customer.selectedCustomerDomainForEvent.domainId
    : null;
  if (customer.selectedCustomerDomainForEvent != null) {
    try {
      const response = yield call(getDomainCampaignReportCall, domainId);
      if (response) {
        yield put(getDomainCampaignReportSuccess(response.data));
      }
    } catch (error) {
      yield put(getDomainCampaignReportFailure(error.response.data));
    }
  } else {
    try {
      let user = yield select(authUser);
      let domainId = user.currentDomain.id;
      const response = yield call(getDomainCampaignReportCall, domainId);
      if (response) {
        yield put(getDomainCampaignReportSuccess(response.data));
      }
    } catch (error) {
      yield put(getDomainCampaignReportFailure(error.response.data));
    }
  }
}
function* getDomainLedgerReport() {
  let customer = yield select(reports);
  let domainId = customer.selectedCustomerDomainForEvent
    ? customer.selectedCustomerDomainForEvent.domainId
    : null;
  if (customer.selectedCustomerDomainForEvent != null) {
    try {
      const response = yield call(getDomainLedgerReportCall, domainId);
      if (response) {
        yield put(getDomainLedgerReportSuccess(response.data));
      }
    } catch (error) {
      yield put(getDomainLedgerReportFailure(error.response.data));
    }
  } else {
    try {
      let user = yield select(authUser);
      let domainId = user.currentDomain.id;
      const response = yield call(getDomainLedgerReportCall, domainId);
      if (response) {
        yield put(getDomainLedgerReportSuccess(response.data));
      }
    } catch (error) {
      yield put(getDomainLedgerReportFailure(error.response.data));
    }
  }
}

/**
 * Get Consent Report
 */
function* getConsentReport() {
  try {
    let report = yield select(reports);
    const response = yield call(
      getConsentDataFromServer,
      report.consentFilterDays
    );
    if (response) {
      yield put(getCustomerConsentReportSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    //yield put(getCustomerConsentReportFailure(error.response.data.error));
  }
}

/**
 * Get Consumer Report
 */
function* getConsumerReport() {
  try {
    const response = yield call(getConsumerDataFromServer);
    yield put(getCustomerConsumerReportSuccess(response));
  } catch (error) {
    yield put(getCustomerConsumerReportFailure(error.response.data.error));
  }
}

export const reportsSagas = [
  takeEvery("GET_CUSTOMER_EVENT_REPORT", getEventReport),
  takeEvery("GET_CUSTOMER_LEDGER_REPORT", getLedgerReport),
  takeEvery("GET_CUSTOMER_LIST", getCustomers),
  takeEvery("EXECUTE_PAYMENT", executePayment),
  takeEvery("REFUND_PAYMENT", refundPayment),
  takeEvery("GET_CHANNEL_COUNT_REPORT", getChannelCount),
  takeEvery("SELECT_CUSTOMER_DOMAIN", getChannelCountForDomain),
  takeEvery("GET_DOMAIN_EVENTS", getDomainEventsReport),
  takeEvery("GET_DOMAIN_CAMPAIGN_REPORT", getDomainCampainReport),
  takeEvery("GET_DOMAIN_LEDGER_REPORT", getDomainLedgerReport),
  takeEvery("GET_CUSTOMER_CONSENT_REPORT", getConsentReport),
  takeEvery("GET_CUSTOMER_CONSUMER_REPORT", getConsumerReport),

  // takeEvery("CHANGE_DOMAIN",)
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...reportsSagas]);
}
